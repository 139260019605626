import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { forkJoin, of, throwError } from 'rxjs';
import { concatMap } from 'rxjs/operators';

import { TemplateType } from '@pa/references/idf';
import { BehaviourService, mapAttachments } from '@pa/lib-spa';
import { Behaviour, IncidentContact } from '@pa/sdk/idf';

import { AuthService } from '../core/auth/auth.service';
import { ClaimsService } from './claims.service';
import { PolicyService } from '../services/policy.service';
import { formatDate } from '../utils/temporal';
import { mapDocumentationLinks } from '../core/utils/attachment.mapping';
import { Attachment } from '@pa/lib-spa/types';
import { getTransactionType } from '../utils/common';

// NOTE: Copied from ServicePortal. Consider moving to @pa/lib-spa as a util
const getFileTemplateType = (filename: string, behaviour: Behaviour) =>
    behaviour.documentFilenames
        // Need to sort docs with 'wording' to the bottom so 'policy' and 'quote' are found first
        .sort((file) => (file.output.includes('wording') ? -1 : 1))
        .find((file) => filename.includes(file.output))?.type;

interface BasicInfo {
    companyName: string;
    dateOfLoss: string;
    documents: { display: string; url: string }[];
    expiryDate: string;
    reference: string;
    renewalDate: string;
    policyDocuments: Attachment[];
    fnolDocument: { display: string; url: string };
    contact?: IncidentContact;
}
@Component({
    selector: 'cp-claims',
    templateUrl: './claims.page.html',
})
export class ClaimsPage implements OnInit {
    public basicInfo: BasicInfo;
    public loading = true;

    private _incidentReportId: string;
    private _behaviourId: string;

    constructor(
        private _router: ActivatedRoute,
        private _authService: AuthService,
        private _behaviourService: BehaviourService,
        private _claimsService: ClaimsService,
        private _policiesService: PolicyService
    ) {}

    ngOnInit() {
        this._router.queryParams
            .pipe(
                concatMap((params) => {
                    const { behaviour, incidentReport } = params;

                    if (behaviour && incidentReport) {
                        return forkJoin([
                            this._behaviourService.getAndSetNewBehaviour(behaviour),
                            this._claimsService.getIncidentReport(behaviour, incidentReport),
                        ]);
                    }

                    return throwError(new Error('Expected a behaviour and an incident report id'));
                }),
                concatMap(([b, ir]) => {
                    return forkJoin([
                        of(b),
                        of(ir),
                        this._policiesService.getActivePolicy(ir.clientPolicy, ir.dateOfLoss, ir.behaviour),
                    ]);
                }),
                concatMap(([b, ir, cp]) => {
                    return forkJoin([of(b), of(ir), of(cp), this._claimsService.getClient(b._id, cp.paClient)]);
                })
            )
            .subscribe({
                next: ([behaviour, incidentReport, clientPolicy, paClient]) => {
                    let fnolDocument = { display: '', url: '' };
                    const documents = (incidentReport as any).documentationLinks
                        .map((d) => {
                            const templateType = getFileTemplateType(d.filename, behaviour);

                            switch (templateType) {
                                case TemplateType.incidentReport: {
                                    fnolDocument = { display: 'Incident Report', url: d.url };
                                    return;
                                }
                                default: {
                                    const fileNameValue = d.filename.split('_');
                                    let extension = '.pdf';
                                    if (fileNameValue[0] === 'image') {
                                        extension = '.png';
                                    }
                                    return {
                                        display: `${fileNameValue[0]}_${fileNameValue[1]}${extension}`,
                                        url: d.url,
                                    };
                                }
                            }
                        })
                        .filter((d) => !!d);
                    const timezone = clientPolicy.timezone;
                    const documentLinks: Attachment[] = mapDocumentationLinks(clientPolicy.documentationLinks);

                    this.basicInfo = {
                        companyName: paClient.companyName,
                        dateOfLoss: formatDate(incidentReport.dateOfLoss, timezone),
                        documents,
                        expiryDate: formatDate(
                            moment.utc(clientPolicy.expiryDate).subtract(1, 'd').toISOString(),
                            timezone
                        ),
                        reference: clientPolicy.reference,
                        renewalDate: formatDate(clientPolicy.renewalDate ?? clientPolicy.inceptionDate, timezone),
                        policyDocuments: mapAttachments(documentLinks, behaviour, getTransactionType(clientPolicy)),
                        fnolDocument,
                        contact: incidentReport.contact ? incidentReport.contact : undefined,
                    };

                    this.loading = false;
                },
                error: (err) => {
                    this.loading = false;
                    console.error(err);
                },
            });
    }
}
