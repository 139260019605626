import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { THEME_NAME, ThemeService } from '@pa/lib-spa';
import { Hub } from 'aws-amplify';

import version from '../../package.json';

import { ApplicationState, AuthService } from './core/auth/auth.service';
import { SdkService } from './services/sdk.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    appReady: boolean = false;
    authState: boolean = false;
    public theme: string;
    public themeName = THEME_NAME;
    public version = version;

    constructor(
        private router: ActivatedRoute,
        private authService: AuthService,
        private themeService: ThemeService,
        private sdkService: SdkService,
        private authenticator: AuthenticatorService
    ) {}

    async ngOnInit() {
        this.router.queryParams.subscribe((params) => {});
        this.themeService.theme.subscribe((theme) => {
            this.theme = theme.slug;
        });

        this.authService.appState.subscribe((state) => {
            this.appReady = state === ApplicationState.ready;
        });

        Hub.listen('auth', async (data) => {
            switch (data.payload.event) {
                case 'signIn':
                    await this.authService.postSignIn();
                    break;
                case 'signUp':
                    // console.log('user signed up');
                    break;
                case 'signOut':
                    // console.log('user signed out');
                    break;
                case 'signIn_failure':
                    // console.log('user sign in failed');
                    break;
                case 'configured':
                // console.log('the Auth module is configured');
            }
        });

        if (await this.authService.isLoggedIn()) {
            await this.authService.postSignIn();
        }
    }
}
